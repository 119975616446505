<template>
  <div class="edit">
    <img src="@/assets/img/back.png" alt="" class="back" @click="$router.replace('/user')">
    <div v-if="$route.query.info==='name'">
      <div class="title">我的名字</div>
      <van-field v-model="userData.name" :error="!userData.name" clearable placeholder="请输入姓名" />
    </div>
    <div v-if="$route.query.info==='height'">
      <div class="title">我的身高</div>
      <van-field v-model="userData.height" :error="!userData.height" clearable placeholder="请输入身高" />
    </div>
    <div v-if="$route.query.info==='weight'">
      <div class="title">我的体重</div>
      <van-field v-model="userData.weight" :error="!userData.weight" clearable placeholder="请输入体重" />
    </div>
    <div v-if="$route.query.info==='idCard'">
      <div class="title">我的身份证号</div>
      <van-field v-model="userData.idCard" :error="!userData.idCard" clearable placeholder="请输入身份证号码" />
    </div>
    <van-button round block type="primary"  @click="submit">保存</van-button>
  </div>
</template>

<script>
import { getUser, partialUpdateUser } from "@/api/getData";
import { validator } from "@/utils/validate";

export default {
  name: 'Edit',
  data() {
    return {
      userData: {},
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    //获取用户数据
    getUser() {
      getUser(this.vUserId).then(res => {
        this.userData = res.data
      })
    },
    //保存信息
    submit() {
      if (this.$route.query.info === 'name' && !this.userData.name) return
      if (this.$route.query.info === 'height' && !this.userData.height) return
      if (this.$route.query.info === 'height' && this.userData.height < 1 || this.userData.height > 300) {
        return this.$message.error('请输入正确的身高')
      }
      if (this.$route.query.info === 'weight' && !this.userData.weight) return
      if (this.$route.query.info === 'weight' && this.userData.weight < 1 || this.userData.weight > 300) {
        return this.$message.error('请输入正确的体重')
      }
      if (this.$route.query.info === 'idCard' && !this.userData.idCard) return
      if (this.$route.query.info === 'idCard' && !validator(this.userData.idCard)) {
        return this.$message.error('请输入正确的身份证号码')
      }
      partialUpdateUser(this.vUserId,this.userData).then(() => {
        this.$message.success('信息更新成功')
        this.$router.replace('/user')
      }).catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.edit {
  .back {
    display: block;
    width: 0.31rem;
    margin: 0.16rem 0 0.24rem 0.16rem;
  }
  .noData{
    display: block;
    width: 100%;
    margin: 0 auto;
    width:2rem;
    margin-top: 1.1rem;
  }
  .ins{
    color: #9BA5A5;
    font-size: 0.14rem;
    line-height: 0.24rem;
    text-align: center;
  }
  .title {
    font-size: 0.14rem;
    line-height: 0.32rem;
    margin: 0 0 0.16rem 0.16rem;
    color: #323233;
  }

  :deep(.van-cell) {
    padding: 0 0.16rem;
    font-size: 0.14rem;

    .van-field__control {
      height: 0.33rem;
      border-bottom: 0.01rem solid rgba(0, 0, 0, 0.1);
    }
  }

  .van-button {
    width: 3.43rem !important;
    height: 0.48rem;
    margin: 0.54rem auto 0;
  }
}
</style>